import { defineStore } from 'pinia'
import User from '@/shared/types/user'
import { computed, ref } from 'vue'
import axios from 'axios'
import { SuccessResponse } from '@/shared/types/responses'
import { getAuthenticatedUser } from '@/shared/utils/endpoints'
import { useLogger } from 'vue-logger-plugin'
import { useQueryClient } from '@tanstack/vue-query'

export function clearAuthData() {
    console.log('Clearing auth data')
    localStorage.removeItem('user')
    localStorage.removeItem('authToken')
    delete axios.defaults.headers.common['Authorization']
}

export const useAuthStore = defineStore(
    'auth',
    () => {
        const logger = useLogger()
        const isUserLoaded = ref(false)
        const user = ref<User>(
            JSON.parse(localStorage.getItem('user') || 'null')
        )
        const authToken = ref(localStorage.getItem('authToken') || null)
        const queryClient = useQueryClient()

        const isLoggedIn = computed(() => !!user.value && !!authToken.value)

        const initialize = async () => {
            const storedToken = localStorage.getItem('authToken')
            if (storedToken) {
                setAuthToken(storedToken)
                try {
                    await getUser()
                } catch (error) {
                    clearAuthData()
                }
            }
        }

        const setUser = (newUser: User | null) => {
            logger.debug('Setting user:', newUser)
            user.value = newUser
            if (newUser) {
                //if(newUser.reconciliations) { reconcilationAuthStore.setReconciliations(newUser.reconciliations) }
                localStorage.setItem('user', JSON.stringify(newUser))
            }
        }

        const setAuthToken = (token: string | null) => {
            authToken.value = token
            if (token) {
                localStorage.setItem('authToken', token)
                axios.defaults.headers.common['Authorization'] =
                    `Bearer ${token}`
            }
        }

        const federate = async (
            email: string,
            referral_code?: string,
            use_code?: boolean
        ) => {
            const { data } = await axios.post<
                SuccessResponse<{
                    pwd: boolean
                    otp_sent: boolean
                    email: string
                    email_verified_at: string | null
                    referred_by: string | null
                }>
            >('/api/v1/auth/federate', {
                email,
                referral_code,
                use_code,
                tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
                locale: navigator.language,
                ua: navigator.userAgent,
            })
            return data
        }

        const login = async (credentials: {
            referred_by_code?: string
            method: 'password' | 'otp' | 'nonce'
            email?: string
            password?: string
            otp?: string
            email_hash?: string
            nonce?: string
        }) => {
            try {
                await axios.get('/sanctum/csrf-cookie')
                const { data } = await axios.post<
                    SuccessResponse<{ user: User; token: string }>
                >('/api/v1/auth/login', credentials)
                setUser(data.data.user)
                setAuthToken(data.data.token)
                return { type: 'success', data: data.data }
            } catch (error: any) {
                if (error.response && error.response.status === 401) {
                    return {
                        type: 'Login error:',
                        message: error.response.data.message,
                    }
                } else {
                    throw error
                }
            }
        }

        const logout = async () => {
            try {
                await axios.post('/api/v1/auth/logout')
            } catch (error) {
                console.error('Error during logout:', error)
            } finally {
                setUser(null)
                setAuthToken(null)
                user.value = null
                authToken.value = null
                clearAuthData()
            }
        }

        const getUser = async () => {
            logger.debug('Getting user:', user.value)
            if (!authToken.value) {
                return null
            }
            try {
                const response = await getAuthenticatedUser()
                // set response to query cache

                if (response && response.data) {
                    queryClient.setQueryData(['user'], response.data)
                    isUserLoaded.value = true
                    setUser(response.data)
                    return response.data
                } else {
                    setUser(null)
                    setAuthToken(null)
                    return null
                }
            } catch (error) {
                console.error('Error fetching user:', error)
                setUser(null)
                setAuthToken(null)
                throw error
            }
        }

        if (authToken.value) {
            axios.defaults.headers.common['Authorization'] =
                `Bearer ${authToken.value}`
        }

        return {
            user,
            authToken,
            isLoggedIn,
            isUserLoaded,
            federate,
            initialize,
            login,
            logout,
            getUser,
            setUser,
            setAuthToken,
        }
    },
    {
        persist: false, // We're handling persistence manually
    }
)
