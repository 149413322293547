// /resources/shared/utils/toast.ts

import { h, ComponentPublicInstance } from 'vue';
import { toast, ToastOptions } from 'vue3-toastify';
import CustomToast from '@/shared/components/CustomToast.vue';

// Define the type for the icon component
type IconComponent = ComponentPublicInstance | null;

// Define the interface for the toast options
interface ShowToastProps {
    title?: string;
    message: string;
    iconUrl?: string;
    iconComponent?: IconComponent;
    type?: 'default' | 'error' | 'info' | 'success' | 'warning';
}

interface ShowErrorToastProps {
    title: string;
    message: string;
    iconComponent?: IconComponent;
}

// Function to show a toast notification
export function showToast({
                              title = '',
                              message,
                              iconUrl = '',
                              iconComponent = null,
                              type = 'default',
                          }: ShowToastProps): void {
    // Define the options for the toast
    const options: ToastOptions = {
        autoClose: true,
        closeOnClick: false,
        pauseOnHover: true,
        toastClassName: '!flex !items-start !p-1 bg-white !rounded-lg shadow-lg',
        bodyClassName: '!flex !flex-row !gap-2 text-left !p-1',
    };

    // Render the custom toast component
    toast(
        h(CustomToast, {
            title,
            message,
            iconUrl,
            iconComponent,
        }),
        options
    );
}

// Function to show an error toast
export function showErrorToast({ title, message, iconComponent }: ShowErrorToastProps): void {
    showToast({
        title,
        message,
        iconComponent,
        type: 'error',
    });
}
