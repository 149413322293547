<!-- @/App.vue -->
<template>
    <meta-tags
        :description="metaDescription"
        :image="metaImage"
        :title="metaTitle"
    />
    <router-view v-slot="{ Component, route }">
        <component
            :is="Component"
            :is-conversation-active="isConversationRoute(route)"
        />
    </router-view>
    <Toaster />
</template>

<script lang="ts" setup>
import {
    computed,
    onMounted,
    onUnmounted,
    provide,
    reactive,
    ref,
    watch,
} from 'vue'
import { uaInjectKey } from '@/shared/types/inject'
import { useAuthStore } from '@/shared/stores/auth'
import useModal from '@/shared/composables/useModal'
import { usePagesStore } from '@/shared/stores/pages'
import { useRoute, useRouter } from 'vue-router'
import useChannel from '@/shared/composables/useChannel'
import { getProjectElements } from '@/shared/utils/endpoints'
import { useProjectStore } from '@/shared/stores/project'
import MetaTags from '@/shared/components/MetaTags.vue'
import { useEcho } from '@/shared/types/useEcho'
import { useFacebookPixel } from '@/shared/composables/useFacebookPixel'
import AnnouncementModal from '@/shared/components/modals/AnnouncementModal.vue'
import { useLogger } from 'vue-logger-plugin'
import NamePromptModal from '@/shared/components/modals/NamePromptModal.vue'
import { Toaster, useToast } from '@/shared/components/ui/toast'
import ErrorIcon from '@/shared/components/icons/ErrorIcon.vue'
import { showToast } from '@/shared/utils/toast'

const pixelId = import.meta.env.VITE_FACEBOOK_PIXEL_ID as string | undefined
const { initialize: initializeFbPixel, trackCustomEvent } =
    useFacebookPixel(pixelId)

const metaTitle = computed(() => {
    return (
        'Speak Your Story to Life | StoryFlame' || (route.meta.title as string)
    )
})
const metaDescription = computed(() => {
    return (
        'With innovative speech-to-story technology and an intuitive story-crafting interface, StoryFlame is your partner in transforming your story vision into a comprehensive outline.' ||
        (route.meta.description as string)
    )
})
const metaImage = computed(() => {
    return '/images/og-image.jpg' || (route.meta.image as string)
})

const isConversationRoute = (route) => {
    return [
        'conversation',
        'achievement-dynamic-conversation',
        'narrative-element-dynamic-conversation',
        'dynamic-conversation',
    ].includes(route.name)
}

const authStore = useAuthStore()
const { show } = useModal()
const { transition, setTransition } = usePagesStore()

const router = useRouter()
const route = useRoute()
const logger = useLogger()

const currentProjectElements = ref(null)
const navHeight = ref(0)
const projectStore = useProjectStore()

provide('narrativeElementSlug', route.params.narrativeElementSlug)
provide('achievementSlug', route.query.achievement)

provide(
    'sideMenuConfig',
    reactive({
        create: null,
        develop: null,
        ideate: null,
        update: null,
    })
)

const useFullWidth = ref(false)

provide('projectStore', projectStore)

async function fetchProjectElements(slug: string, categoryModelPlural: string) {
    try {
        const elements = await getProjectElements(slug, categoryModelPlural)
        currentProjectElements.value = elements
    } catch (error) {
        console.error('Failed to fetch project elements:', error)
        throw error
    }
}

provide('useFullWidth', useFullWidth)

// router.beforeEach((to, from, next) => {
//     if (from.name === undefined && from.path === '/') setTransition('none')
//     else if (!transition.name.endsWith('-back')) {
//         if (to.meta.transition) {
//             setTransition(to.meta.transition)
//         } else {
//             setTransition('default')
//         }
//     }
//     next()
// })

const { listen, leave, leaveAll } = useChannel()
const echo = useEcho()
const toastv2 = useToast()

const ua = reactive({
    agent: '',
    isMobile: false,
})

provide(uaInjectKey, ua)

const initializeApp = async () => {
    ua.agent = navigator.userAgent
    ua.isMobile = /Mobi/.test(ua.agent)

    // Calculate the height of the fixed elements
    const freeTrialBanner = document.getElementById('free-trial-banner')
    const mainNav = document.getElementById('main-nav')
    let totalHeight = 0
    if (freeTrialBanner) totalHeight += freeTrialBanner.offsetHeight
    if (mainNav) totalHeight += mainNav.offsetHeight

    navHeight.value = totalHeight
}

watch(
    () => authStore.isLoggedIn,
    (newValue) => {
        if (
            !newValue &&
            !['login', 'magic-link'].includes(route.name as string)
        ) {
            router.push({ name: 'login' })
        }
    }
)

let listeningUserId = null as number | null
watch(
    () => authStore.user,
    (value, oldValue) => {
        // todo: move this to user composable
        console.log('User changed:', value, oldValue)
        if (value && value.id && listeningUserId !== value.id) {
            listeningUserId = value.id
            console.log('User logged in, starting listening', authStore.user)

            // check if echo is initialized
            if (!echo.echo.value) echo.init()

            // subscribe to private channel
            const channel = listen(
                'private',
                `App.Models.User.${listeningUserId}`
            )

            // listen for events
            channel
                ?.listen('.achievement.unlocked', (e) => {
                    showToast({
                        title: e.title,
                        message: e.message,
                        iconUrl: `/storage/${e.icon}`,
                    })
                })
                .listen('.announcement', (e) => {
                    // todo: @mitch can change this to full screen modal because popup looks bad?
                    show(AnnouncementModal, {
                        type: 'full',
                        announcement_type: e.type,
                        title: e.title,
                        subtitle: e.subtitle,
                        content: e.content,
                    })
                })
                .listen('.elements.created', (e) => {
                    e.elements.forEach((element) => {
                        const { type, title, message, count } = element
                        showToast({
                            title: title,
                            message: message,
                        })
                    })
                })
                .listen('.job.progress', (e) => {
                    console.log('Push Notification - Job progress update:', e)
                    // TODO: @mitch, let's consider showing some of this to the user... even for a progress bar.
                    // toast.info(`Job ${e.job_uid}: ${e.message} (${e.current_step}/${e.total_steps})`)
                })
                .listen('.image.generation.update', (e) => {
                    const { status, title, message, subject, imageUrl } = e
                    if (status === 'completed') {
                        showToast({
                            title: title,
                            message: message,
                            iconUrl: `/storage/${imageUrl}`,
                        })
                    } else if (status === 'failed') {
                        showToast({
                            title: title,
                            message: message,
                            iconComponent: ErrorIcon,
                        })
                    }
                })
                .listen('.toast.notification', (e) => {
                    console.log('Push Notification - Toast:', e)
                    const { title, message, imageUrl } = e.payload
                    showToast({
                        title: title,
                        message: message,
                        iconUrl: imageUrl
                            ? imageUrl.startsWith('http')
                                ? imageUrl
                                : `/storage/${imageUrl}`
                            : null,
                    })
                })
            // Add any logic for when a user logs in
            logger.debug('Listening for unlocked achievements')
        } else if (!value && oldValue) {
            logger.debug('User logged out, stopping listening')
            // Add any logic for when a user logs out
            leaveAll()
        }
    },
    { deep: true, immediate: true }
)

onMounted(async () => {
    await authStore.initialize()
    await initializeApp()
    if (authStore.user?.first_name === null) {
        show(NamePromptModal, {
            type: 'full',
        })
    }

    console.log('query', route.query)

    if (!import.meta.env.DEV) {
        if (pixelId) {
            await initializeFbPixel()

            const urlParams = new URLSearchParams(window.location.search)
            const referral = urlParams.get('referral')

            if (referral) {
                console.log('Referral:', referral)

                const storedReferral = localStorage.getItem('referral')
                if (!storedReferral || storedReferral !== referral) {
                    localStorage.setItem('referral', referral)
                }

                trackCustomEvent('Referral', { referralSource: referral })
            }
        } else {
            console.warn(
                'Facebook Pixel ID is not set in environment variables.'
            )
        }
    } else {
        logger.debug('Skipping Facebook Pixel initialization in development')
    }
})

onUnmounted(async () => {
    if (authStore.isLoggedIn && route.name === 'login') {
        void router.push({ name: 'home' })
    }
    leaveAll()
})
</script>

<style></style>
