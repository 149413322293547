// /resources/shared/plugins/modalPlugin.ts
import {
    App,
    Component,
    defineAsyncComponent,
    InjectionKey,
    markRaw,
    reactive,
} from 'vue'
import ModalContainer from '@/shared/plugins/ModalContainer.vue'
import { mountComponent, MountedComponentInstance } from 'vue-mountable'

export type ModalEventCallback = (
    payload: any[],
    close: (force?: boolean) => void,
    modal: ModalDefinition
) => void

export type ModalDefinition = {
    id: string
    modalName?: string
    to?: string
    component: Component
    props: Record<string, any>
    events: Record<string, ModalEventCallback>
    open: boolean
}

export const modalInjectKey = Symbol() as InjectionKey<{
    show: (
        component: Component | string,
        props?: Record<string, any>,
        events?: Record<string, ModalEventCallback>,
        options?: { to?: string }
    ) => string
    close: (id?: string, force?: boolean) => void
    modals: ModalDefinition[]
}>

export default {
    install: (app: App) => {
        let container: MountedComponentInstance | null = null
        const modals = reactive<ModalDefinition[]>([])
        const mountContainer = () => {
            if (container) return container
            container = mountComponent({
                component: ModalContainer,
                props: { modals },
            })
        }

        const defaultComponents = {
            'full-screen-loader': {
                component: defineAsyncComponent(
                    () =>
                        import(
                            '@/shared/components/modals/FullScreenLoader.vue'
                        )
                ),
                props: {
                    type: 'full',
                    backdrop: false,
                },
            },
            'full-screen-summary': {
                component: defineAsyncComponent(
                    () =>
                        import(
                            '@/shared/components/modals/FullScreenSummary.vue'
                        )
                ),
                props: {
                    type: 'full',
                    backdrop: false,
                },
            },
            'repeat-answer': {
                component: defineAsyncComponent(
                    () => import('@/shared/components/modals/RepeatAnswer.vue')
                ),
            },
            error: {
                component: defineAsyncComponent(
                    () => import('@/shared/components/modals/ErrorPopup.vue')
                ),
                props: {
                    type: 'error',
                },
            },
            confirm: {
                component: defineAsyncComponent(
                    () => import('@/shared/components/modals/ConfirmModal.vue')
                ),
                props: {
                    type: 'full',
                    backdrop: false,
                },
            },
            form: {
                component: defineAsyncComponent(
                    () => import('@/shared/components/modals/FormModal.vue')
                ),
                props: {
                    type: 'full',
                    backdrop: false,
                },
            },
            reconciliation: {
                component: defineAsyncComponent(
                    () =>
                        import(
                            '@/shared/components/modals/ReconciliationPopup.vue'
                        )
                ),
                props: {
                    type: 'full',
                    backdrop: false,
                },
            },
            'delete-narrative-element': {
                component: defineAsyncComponent(
                    () => import('@/shared/components/modals/ConfirmModal.vue')
                ),
                props: {
                    type: 'full',
                    backdrop: false,
                    title: 'Delete',
                    message: 'Are you sure you want to delete this element?',
                },
            },
            'edit-narrative-element': {
                component: defineAsyncComponent(
                    () => import('@/shared/components/modals/NameEditModal.vue')
                ),
                props: {
                    type: 'modal',
                },
            },
            'conversation-engine': {
                component: defineAsyncComponent(
                    () =>
                        import(
                            '@/shared/components/modals/ConversationEngineModal.vue'
                        )
                ),
                props: {
                    type: 'full',
                    backdrop: false,
                },
            },
            'image-modal': {
                component: defineAsyncComponent(
                    () => import('@/shared/components/modals/ImageModal.vue')
                ),
                props: {
                    type: 'full',
                    backdrop: false,
                },
            },
        }

        const show = (
            component: Component | string,
            props = {},
            events = {},
            options = {}
        ): string => {
            mountContainer()
            const id = Math.random().toString(36).slice(2, 9)

            let modalName = undefined
            if (typeof component === 'string') {
                modalName = component
                const defaultComponent = defaultComponents[component]
                if (!defaultComponent) {
                    throw new Error(
                        `Default component "${component}" not found`
                    )
                }
                component = defaultComponent.component
                props = Object.assign({}, defaultComponent.props, props)
            }

            console.log('Showing modal:', component, 'with props:', props)

            modals.push({
                id,
                modalName,
                component: markRaw(component as Component),
                props,
                events: events,
                open: true,
                to: options.to ?? undefined,
            })
            document.body.classList.add('no-scroll')
            return id
        }

        const close = (id: string | undefined = undefined, force = false) => {
            if (force) {
                if (!id) {
                    modals.splice(0, modals.length)
                } else {
                    const index = modals.findIndex(
                        (m) => m.id === id || m.modalName === id
                    )
                    if (index !== -1) {
                        modals.splice(index, 1)
                    }
                }
                if (modals.length === 0)
                    document.body.classList.remove('no-scroll')
            } else {
                if (!id) {
                    const modal = modals[modals.length - 1]
                    if (modal) {
                        modal.open = false
                    }
                } else {
                    const modal = modals.find(
                        (m) => m.id === id || m.modalName === id
                    )
                    if (modal) {
                        modal.open = false
                    }
                }
            }
        }

        app.provide(modalInjectKey, {
            show,
            close,
            modals,
        })
    },
}
