import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import { ref, watch } from 'vue'
import { useAuthStore } from '@/shared/stores/auth'
import { useLogger } from 'vue-logger-plugin'

const echo = ref<Echo | undefined>()

export function useEcho() {
    const { authToken } = useAuthStore()
    const logger = useLogger()

    const createEcho = (token) => {
        console.debug('Creating echo')
        return new Echo({
            broadcaster: 'pusher',
            client: new Pusher(import.meta.env.VITE_APP_PUSHER_APP_KEY, {
                cluster: import.meta.env.VITE_APP_PUSHER_APP_CLUSTER,
                forceTLS: true,
                authEndpoint: '/broadcasting/auth',
                auth: {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                },
            }),
        })
    }

    if (!echo.value && authToken)
        echo.value = createEcho(localStorage.getItem('authToken'))

    watch(
        () => authToken,
        (token) => {
            echo.value?.leaveAllChannels()
            echo.value?.disconnect()
            console.warn('token changed, leave all echo channels')
            if (token) {
                echo.value = createEcho(localStorage.getItem('authToken'))
            }
        }
    )

    return {
        echo,
        reinit() {
            echo.value?.leaveAllChannels()
            echo.value?.disconnect()
            echo.value = createEcho(localStorage.getItem('authToken'))
        },
        init() {
            console.log('init', echo.value)
            if (!echo.value) {
                console.log('Echo not initialized')
                echo.value = createEcho(localStorage.getItem('authToken'))
            } else {
                console.warn('Echo already initialized')
            }
        },
    }
}
